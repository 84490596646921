import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			// deviceType: 'pc',
			deviceType: 'mobile',
			showDebug: false,
			ads: {
				home_1: {
					class: 'adsbygoogle',
					style: 'display:block',
					'data-ad-client': 'ca-pub-7151867298880152',
					'data-ad-slot': '1629776512',
					'data-ad-format': 'auto',
					'data-full-width-responsive': 'true',
				},
				home_recommand: [
					{
						class: 'adsbygoogle',
						style: 'display:block',
						'data-ad-client': 'ca-pub-7151867298880152',
						'data-ad-slot': '1629776512',
						'data-ad-format': 'auto',
						'data-full-width-responsive': 'true',
					},
					{
						class: 'adsbygoogle',
						style: 'display:block',
						'data-ad-client': 'ca-pub-7151867298880152',
						'data-ad-slot': '1629776512',
						'data-ad-format': 'auto',
						'data-full-width-responsive': 'true',
					},
				],
				detail_1: {
					class: 'adsbygoogle',
					style: 'display:block',
					'data-ad-client': 'ca-pub-7151867298880152',
					'data-ad-slot': '1629776512',
					'data-ad-format': 'auto',
					'data-full-width-responsive': 'true',
				},
				detail_2: {
					class: 'adsbygoogle',
					style: 'display:block',
					'data-ad-client': 'ca-pub-7151867298880152',
					'data-ad-slot': '1629776512',
					'data-ad-format': 'auto',
					'data-full-width-responsive': 'true',
				},
				detail_recommand: [
					{
						class: 'adsbygoogle',
						style: 'display:block',
						'data-ad-client': 'ca-pub-7151867298880152',
						'data-ad-slot': '1629776512',
						'data-ad-format': 'auto',
						'data-full-width-responsive': 'true',
					},
				],
			},
			adPosConfig: {
				homeRecommand: [2, 4],
				homeRecommandPC: [2],
			},

			adsensConfig: {},
			domainConfig: {},
			wikiList: [],
			tagList: [],
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
			setAdsense(state, adsenseConfig) {
				state.adsensConfig = adsenseConfig
				},
			setWikiList(state, wikiList) {
				state.wikiList = wikiList
			},
			setTagList(state, tagList) {
				state.tagList = tagList
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			adPosConfig: (state) => state.adPosConfig,
			domainConfig: (state) => state.domainConfig,
			wikiList: (state) => state.wikiList,
			tagList: (state) => state.tagList,
		},
	})
}
